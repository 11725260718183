<template>
    <div class="settingContractApproval">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active> Contract Approvals</CBreadcrumbItem>
        </teleport>

        <CCard style="min-height: 75vh">
            <CCardBody class="position-relative">
                <ConfirmDialog ref="confirmDialog"/>
                <CRow class="flex-column-reverse flex-lg-row">
                    <CCol class="col-12 col-lg-6 col-xxl-8">
                        <Form ref="form" :form="form"/>
                    </CCol>
                    <CCol class="col-12 col-lg-6 col-xxl-4">
                        <CRow :xs="{ cols: 1, gutter: 4 }">
                            <CCol class="text-end">
                                <CButton color="primary" :disabled="!form.id" @click="handleNew">
                                    Add new workflow
                                </CButton>
                            </CCol>
                            <CCol>
                                <SmartTable :key="key" :items="workflows" :items-per-page="parseInt(workflows.length)"
                                            :columns="fields" :sorter-value="sort" :selected-items="selected"
                                            :loading="loadingTable" :active-page="activePage" :per-page="perPage"
                                            :clear-text=" search.length ? 'No search results found' : 'No Data yet'"
                                            :pages="pages" @sorter-change="onSort"
                                            @selected-items-change="(s) => (selected = s)"
                                            @update-active-page="(value) => (activePage = value)"
                                            @update-per-page="(value) => (perPage = value)">
                                    <template #name="{ item }">
                                        <span class="tableLink pointer" @click="checkCanEdit(item.id)">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template #clear_text>
                                        <div
                                            class="table__clear d-flex align-items-center justify-content-center w-100">
                                            <div class="tableEmptyTitle">No Workflow created yet</div>
                                            <div class="tableEmptyDescription">But we are almost there!</div>
                                            <img class="tableEmptyImg" src="@/assets/workflow_table.png"
                                                 alt="Workflow"/>
                                        </div>
                                    </template>
                                    <template #button="{ item }">
                                        <div class="d-flex justify-content-around">
                                            <CIcon name="cil-pencil" class="m-2 text-primary pointer"
                                                   @click="checkCanEdit(item.id)"/>
                                            <CIcon name="cil-trash" class="m-2 text-error pointer"
                                                   @click="deleteId(item.id)"/>
                                        </div>
                                    </template>
                                </SmartTable>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <CRow class="mt-3 row-action">
                    <CCol class="col-12 col-lg-6 col-xxl-8">
                        <CCol class="p-0 mb-4">
                            <CDivider/>
                        </CCol>
                        <CCol>
                            <CRow :xs="{ gutterY: 3 }" :xxl="{ gutterY: 0 }" class="w-100">
                                <CCol class="col-12 col-xxl-auto">
                                    <CButton class="w-100" color="primary" variant="outline" :disabled="disabled"
                                             @click="handleCancel">
                                        Cancel
                                    </CButton>
                                </CCol>
                                <CCol class="col-12 col-xxl-auto">
                                    <CButton class="w-100" color="primary" :disabled="loading" @click="save">
                                        Save
                                    </CButton>
                                </CCol>
                                <CCol class="col-12 col-xxl-auto ms-auto">
                                    <CButton v-if="form.id" variant="ghost" class="w-100 text-error"
                                             @click="deleteId(form.id)">
                                        <CIcon name="cilTrash"/>
                                        Delete workflow
                                    </CButton>
                                    <CButton v-else variant="ghost" class="w-100 text-disabled">
                                        <CIcon name="cilTrash"/>
                                        Delete workflow
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import Form from './components/Form'
import mixin from '../approval/mixin'
import {DEF_ITEM} from '../approval/constants'
import {parseError} from '@/utils/api'

export default {
    mixins: [mixin],
    components: {Form},
    data() {
        return {
            mountedComponent: false,
            form: {
                name: '',
                document_approvals: [
                    {position_id: null, mandatory: false, has_condition: false},
                ],
                signature_approvals: [
                    {position_id: null, mandatory: true, has_condition: false},
                ],
            },
            fields: [{key: 'name', label: 'Contract Workflow Name', _style: ''}],
            defaultForm: {
                name: '',
                document_approvals: [this.$deepClone(DEF_ITEM)],
                signature_approvals: [
                    {position_id: null, mandatory: true, has_condition: false},
                ],
            },
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
    },
    methods: {
        mapData(data) {
            if (!data.document_approvals.length) {
                data.document_approvals.push({
                    position_id: null,
                    mandatory: false,
                    has_condition: false,
                })
            }

            if (!data.signature_approvals.length) {
                data.signature_approvals.push({
                    position_id: null,
                    mandatory: true,
                    has_condition: false,
                })
            }

            data.document_approvals.forEach((el, index) => {
                data.document_approvals[index].condition_value = el.condition_value
                    ? Number(el.condition_value).toFixed(2)
                    : 0
                data.document_approvals[index].condition_value_2 = el.condition_value
                    ? Number(el.condition_value_2).toFixed(2)
                    : 0
            })

            return data
        },
        mapForm() {
            let form = JSON.parse(JSON.stringify(this.form))
            form.document_approvals = form.document_approvals.filter(
                (el) => el.position_id,
            )
            form.document_approvals.forEach((el, index) => {
                form.document_approvals[index].condition_value = parseInt(
                    el.condition_value,
                )
                form.document_approvals[index].condition_value_2 = parseInt(
                    el.condition_value_2,
                )
            })

            return form
        },
        handleEdit(id) {
            this.errors = {}
            this.$http.setting
                .contractsApprovalRounds(id)
                .then((res) => this.setNew(this.mapData(res.data.data)))
        },
        fetchData() {
            this.loadingTable = true
            this.$http.setting
                .contractsApprovalWorkflowList({
                    params: {
                        per_page: this.perPage,
                        page: this.activePage,
                        sort_field:
                            this.sort && this.sort.column ? this.sort.column : undefined,
                        sort_direction:
                            this.sort && this.sort.state ? this.sort.state : undefined,
                    },
                })
                .then((res) => {
                    this.workflows = res.data.data
                    this.pages = res.data.meta.last_page
                    if (this.activePage > this.pages)
                        this.activePage = this.activePage - 1
                    this.key++
                })
                .finally(() => (this.loadingTable = false))
        },
        deleteWorkflow(id) {
            this.$http.setting
                .deleteContractsApprovalRounds(id)
                .then((res) => {
                    this.noDelete = null
                    this.$modal.close()
                    this.fetchData()
                    this.toast('info', res.data.message)
                    this.setNew()
                })
                .catch(({response}) => {
                    this.errors = response.data.errors
                        ? this.getErrors(response.data.errors)
                        : {}
                    this.toast('warning', response.data.message)
                    if (response.data.projects) {
                        this.noDelete = response.data
                        this.deleteId(id)
                    }
                })
        },
        save() {
            let form = this.mapForm()
            if (!this.$refs.form.deepValidate()) {
                this.loading = true
                const action = form.id
                    ? this.$http.setting.editContractsApprovalRounds(form)
                    : this.$http.setting.createContractsApprovalRounds(form)
                action
                    .then(response => {
                        this.toast('info', response.data.message)
                        this.form.id = response.data.id || this.form.id;
                        this.fetchData()
                    })
                    .catch((errors) => {
                        if (
                            errors.response.status === 409 &&
                            errors.response.data.projects.length
                        ) {
                            this.copyModal(form, errors.response.data.projects)
                        } else {
                            this.$refs.form.validationErrors =
                                parseError(errors).validationMessages || {}
                        }
                    })
                    .finally(() => (this.loading = false))
            }
        },
        copyModal(form, projects) {
            this.$modal
                .open({
                    close: 'Cancel',
                    success: 'Copy',
                    label: 'Impossible to edit workflow ' + form.name,
                    size: 'lg',
                    sublabel: `
              <p class="mb-0">The workflow is already used in projects</p>
              <ul style="list-style-type: none; padding: 0">
              ${projects
                        .map(
                            (item) => `
                <li style="color: #005D9D">
                  <a href="/projects/${item.id}/dashboard" target="_blank">${item.name}</a>
                </li>
              `,
                        )
                        .join('')}
              </ul>
              <span>You can create a copy of the workflow with added changes.</span>
            `,
                })
                .then(() => {
                    this.$modal.loading(true)
                    this.$http.setting
                        .copyContractsApproval(form)
                        .then((res) => {
                            this.toast('info', res.data.message)
                            this.fetchData()
                            this.setNew(res.data.data)
                            this.$modal.close()
                        })
                        .catch((err) => {
                            this.toast('warning', err.response.data.message)
                            this.$modal.loading(false)
                        })
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.settingContractApproval {
    .SmartTable {
        max-height: 400px;
    }

    .tableEmptyTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.002em;
        color: #1C262F;
        line-height: 28px;
    }

    .tableEmptyDescription {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.002em;
        color: #677A89;
    }

    .tableEmptyImg {
        mix-blend-mode: multiply;
        width: 178px;
        height: 145.15px;
    }

    .tableLink {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.002em;
        text-decoration-line: underline;
        color: #0068AD;
    }

    .row-action {
        position: absolute;
        bottom: 0;
        z-index: 10;
        padding-bottom: 20px;
        width: 100%;
    }
}

.card .card-body {
    padding: 1.5rem 1.5rem;
}

</style>
